<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'indexWinPrize',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    const { id } = this.$route.query
    api.command.getList.call(this, {
      url: '/farmActivityDrawPrize/queryWinList',
      current: 1,
      paramsValue: {
        id: id ? id : ''
      }
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '中奖人',
          key: 'name',
          type: 'input'
        },
        {
          name: '联系方式',
          type: 'input',
          key: 'telephone'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '中奖人',
          align: 'left'
        },
        {
          dataIndex: 'telephone',
          title: '联系方式',
          align: 'left'
        },
        {
          dataIndex: 'winTime',
          title: '中奖时间',
          align: 'left'
        },
        {
          dataIndex: 'useTime',
          title: '使用时间',
          align: 'left'
        }
      ]
    },
    getButton() {
      return []
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
